var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-3"},[_vm._m(0),_c('ValidationObserver',{ref:"observer",staticClass:"px-3 px-md-5 px-lg-3 px-xl-5 f-13",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.store($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('p',{staticClass:"mb-2"},[_vm._v(" Tiene máximo 3 correos de asistentes que puede registrar en su cuenta. ")]),_c('ValidationProvider',{staticClass:"mb-2 d-flex align-items-center flex-wrap",attrs:{"tag":"div","name":"correo del asistente","rules":{
                required: true,
                email: true,
                excluded: _vm.emails
            },"custom-messages":{
                excluded: 'Este correo ya ha sido registrado'
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('el-input',{staticClass:"col-12 col-md-8 pl-0 pr-0 pr-md-3 mb-2 mb-md-0",attrs:{"disabled":_vm.emails.length > 2,"type":"email","placeholder":"Ingresar correo del asistente"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('el-button',{staticClass:"col-12 col-md-auto px-4",attrs:{"disabled":invalid,"type":"primary","native-type":"submit"}},[_vm._v("Guardar")]),_c('span',{staticClass:"text-danger d-block mt-1 f-13 col-12 px-0"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_vm._l((_vm.emails),function(email){return _c('div',{key:email,staticClass:"mr-2 el-tag el-tag--light mb-2"},[_vm._v(" "+_vm._s(email)+" "),_c('el-popconfirm',{attrs:{"confirmButtonText":"Si, eliminar el asistente","cancelButtonText":"Cancelar","iconColor":"red","title":"¿Estás seguro de eliminar el correo del asistente?"},on:{"confirm":function($event){return _vm.handleClose(email)}}},[_c('i',{staticClass:"el-icon-close",attrs:{"slot":"reference"},slot:"reference"})])],1)}),_c('p',{staticClass:"mb-0 mt-4"},[_vm._v(" El correo que agregue como asistente accederá a los correos vinculados con las siguientes operaciones ")]),_c('div',{staticClass:"row mx-auto"},[_c('div',{staticClass:"col-md-6 px-0"},[_c('p',{staticClass:"text-primary font-weight-bold mb-2"},[_vm._v(" Operaciones realizadas por los Desarrolladores Inmobiliarios ")]),_c('p',{staticClass:"my-1"},[_vm._v("Validación de Visita")]),_c('p',{staticClass:"my-1"},[_vm._v("Validación de Minuta")]),_c('p',{staticClass:"my-1"},[_vm._v("Validación de Condición de Pago")])]),_c('div',{staticClass:"col-md-6 px-0"},[_c('p',{staticClass:"text-primary font-weight-bold mb-2"},[_vm._v(" Operaciones realizadas por el Agente Inmobiliario ")]),_c('p',{staticClass:"my-1"},[_vm._v("Validación del Detalle de Facturación")]),_c('p',{staticClass:"my-1"},[_vm._v(" Validación de Datos para el pago de la comisión ")])])])]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-bottom pt-2 pb-2 pr-3 pl-3 pl-md-5"},[_c('h1',{staticClass:"my-0 font-weight-normal text-title"},[_vm._v("Mis Asistentes")]),_c('p',{staticClass:"mb-0 mt-1 f-14"},[_vm._v("Correo de seguimiento a mis agentes")])])}]

export { render, staticRenderFns }