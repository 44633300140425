































































































import { Component, Vue, Ref } from 'vue-property-decorator';
import { agentModule } from '@/store/modules/agent';
import { ValidationObserver } from 'vee-validate';

@Component
export default class Assistant extends Vue {
    @Ref() observer!: InstanceType<typeof ValidationObserver>;
    form = {
        email: ''
    };

    get emails() {
        return agentModule.agent?.emailAsistents;
    }

    async store() {
        await agentModule.addAssistant(this.form.email);
        this.form.email = '';
        this.observer.reset();
        this.$notify.success({
            title: 'Éxito!',
            message: 'Se ha agregado el email del asistente.'
        });
    }

    handleClose(value: string) {
        agentModule.removeAssistant(value);
    }
}
